require('@iconify/iconify');

import {Alert, Button, Carousel, Collapse, Dropdown, Modal, Popover, ScrollSpy, Tab, Toast, Tooltip} from 'bootstrap';
import $ from 'jquery';
import Swiper, { Navigation, Pagination } from 'swiper';
import Aos from 'aos';

Swiper.use([Navigation, Pagination]);

// Hotreloaad
if (module.hot) {
    require('../../src/index.hbs')
    module.hot.accept('../../src/index.hbs', function () {
        window.location.reload();
    })

    module.hot.accept();
}

$(function() {
    Aos.init();
});

// Swiper
const swiper = new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 30,
    grabCursor: true,
    freeMode: true,

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
